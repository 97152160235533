import CmsGeneralConfigFrame from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsTooltip from "@/components/cms/cmsTooltip/cmsTooltip";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import AnimationWrapper from "@/components/util/animationWrapper/animationWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useConfirmModal from "@/hooks/useConfirmModal";
import useImageWithMarkersSettings from "@/hooks/useImageWithMarkersSettings";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrIcon from "@/hooks/useStrIcon";
import useWindowSize from "@/hooks/useWindowSize";
import {
  ceSettingById,
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { showNestedConfigModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementImageWithMarkersStoreSetting } from "@/types/ceSettings/ceSettings";
import {
  CEImageWithMarkers,
  CEImageWithMarkersItem,
} from "@/types/content-elements";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Draggable from "react-draggable";
import { Resizable, ResizeCallbackData } from "react-resizable";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ImageWithMarkersContentElementStyleGlobal from "./imageWithMarkersContentElementStyleGlobal";
import ImageWithMarkersContentElementStyleScoped from "./imageWithMarkersContentElementStyleScoped";

export interface ImageWithMarkersContentElementProps {
  content: CEImageWithMarkers;
  position: number;
  ceSettings?: ContentElementImageWithMarkersStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const IMAGE_WITH_MARKERS_CE_NAME = "imageWithMarkersCE";

export default React.memo(function ImageWithMarkersContentElement(
  props: ImageWithMarkersContentElementProps
) {
  const MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX = 300;
  const MARKER_OFFSET = 60;
  const { t: tCms } = useTranslation("cms");
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const IMAGE_WITH_MARKERS_SCOPED_CLASS = useScopedClassName(
    IMAGE_WITH_MARKERS_CE_NAME,
    props.content,
    props.position
  );
  const { getIcon } = useStrIcon();
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();

  const contentRef = useRef<HTMLDivElement>(null);
  const textItemsRef = useRef<any>([]);
  const markersRef = useRef<any>([]);
  const imgRef = useRef<HTMLImageElement>(null);
  const allowTextboxPush = useRef<boolean>(true);
  const allTextboxesVisible = useRef<boolean>(true);
  const collapsedTextBoxes = useRef<Array<boolean>>([]);

  const [positionEditMode, setPositionEditMode] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<number>(-1);
  const [dialogContent, setDialogContent] = useState<
    CEImageWithMarkersItem | undefined
  >();
  // text boxes that are temporarly pushed inside the users view but are positioned outside of it
  const [pushedTextboxIds, setPushedTextboxIds] = useState<Array<number>>([]);
  const [showScaleWarning, setShowScaleWarning] = useState(false);

  const { overlayItemBackgroundColor, overlayItemMarkerColor } =
    useImageWithMarkersSettings(props.ceSettings);

  const { richTextClassName } = useCesStrRichText(
    props.ceSettings?.richtext,
    undefined
  );

  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.content.__component === element.uid
    )?.nestedElement;
  });

  const cfgShowMarkersOnAllSizes = props.content.cfgShowMarkersOnAllSizes
    ? true
    : false;

  const nonDraggableClasses =
    ".pb-richtext, .react-resizable-handle, .temp-pos, .right-controls, .general-config-frame";

  const { showConfirmModal } = useConfirmModal(
    () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `draftPage.content[${props.position}].content`,
          position: selectedMarker,
        })
      );
    },
    () => {
      setSelectedMarker(-1);
    }
  );

  const { showConfirmModal: showResetPositionConfirmModal } = useConfirmModal(
    () => {
      if (props.content.content) {
        props.content.content.forEach((item: any, index: number) => {
          const toolbarHeight = 42;

          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].position`,
              value: { x: 0, y: 0 },
            })
          );

          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
              value: { x: 0, y: 0 },
            })
          );

          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].height`,
              value: Math.round(
                Math.min(
                  Math.max(
                    props.content.cfgImageHeight / 2 - toolbarHeight,
                    50
                  ),
                  MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX
                )
              ),
            })
          );

          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].width`,
              value: Math.round(
                Math.min(
                  Math.max(props.content.cfgImageWidth / 2, 100),
                  MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX
                )
              ),
            })
          );
        });
      }
    }
  );

  const handleOnMarkerDelete = (index: number) => {
    setSelectedMarker(index);
    showConfirmModal({
      title: tCms("nestedContent-deleteImgWithMarkersMarker"),
      content: tCms("nestedContent-deleteImgWithMarkersMarkerLong"),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      xIsDeny: true,
      icon: "circle-exclamation-light",
    });
  };

  const arrLength = props.content.content.length;
  if (textItemsRef.current.length !== arrLength) {
    textItemsRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => textItemsRef.current[i] || createRef());
  }
  if (markersRef.current.length !== arrLength) {
    markersRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => markersRef.current[i] || createRef());
  }

  const toggleDialog = (element?: CEImageWithMarkersItem) => {
    setDialogOpen((prevState: boolean) => !prevState);
    if (element) {
      setDialogContent(element);
    }
  };

  const onTextboxDragStop = (
    position: { x: number; y: number },
    index: number
  ) => {
    lowerTextbox(index);
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].content[${index}].position`,
        value: {
          x: position.x,
          y: position.y,
        },
      })
    );
  };

  const onMarkerDragStop = (
    position: { x: number; y: number },
    index: number
  ) => {
    if (positionEditMode && editView) {
      lowerMarker(index);
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
          value: {
            x: position.x,
            y: position.y,
          },
        })
      );
    }
  };

  const isImgScaledDown = () => {
    const imgDomRect = imgRef.current?.getBoundingClientRect();
    const cfgImageWidth = Math.round(props.content.cfgImageWidth);

    if (imgDomRect) {
      return imgDomRect.width < cfgImageWidth;
    }
    return false;
  };

  const isTextBoxTouchingContainerSide = (item: CEImageWithMarkersItem) => {
    const containerBoundaries: DOMRect =
      contentRef.current?.getBoundingClientRect()!;
    const result = {
      touchingRight: false,
      touchingLeft: false,
      touchingTop: false,
      touchingBottom: false,
    };

    if (containerBoundaries) {
      const textboxRightSide = Math.round(item.position.x + item.width);
      const containerRightSide = Math.round(containerBoundaries.width / 2);
      result.touchingRight = textboxRightSide > containerRightSide;

      const textboxLeftSide = Math.round(item.position.x);
      const containerLeftSide = Math.round(containerBoundaries.width / -2);
      result.touchingLeft = textboxLeftSide < containerLeftSide;

      const textboxTopSide = Math.round(item.position.y);
      const containerTopSide = Math.round(containerBoundaries.height / -2);
      result.touchingTop = textboxTopSide < containerTopSide;

      const textboxBottomSide = Math.round(item.position.y + item.height);
      const containerBottomSide = Math.round(containerBoundaries.height / 2);
      result.touchingBottom = textboxBottomSide > containerBottomSide;
    }
    return result;
  };

  /**
   * Changes a text box visibility
   * - Text box should be visible except:
   * - If text box is outside image and gets pushed inside it -> invisible.
   * - If text box is inside image and gets pushed outside of it -> invisible.
   */
  const updateTextboxVisibility = (
    item: CEImageWithMarkersItem,
    index: number
  ) => {
    const textboxBoundaries: DOMRect =
      textItemsRef.current[index]?.current?.getBoundingClientRect();
    const containerBoundaries: DOMRect =
      contentRef.current?.getBoundingClientRect()!;

    if (textboxBoundaries && containerBoundaries) {
      if (editView) {
        textItemsRef.current[index].current.style.display = "block";
        return;
      }

      const imgWidth = Math.round(props.content.cfgImageWidth);
      const leftImgSide =
        containerBoundaries.right -
        containerBoundaries.width / 2 -
        imgWidth / 2;
      const rightImgSide =
        containerBoundaries.right -
        containerBoundaries.width / 2 +
        imgWidth / 2;

      const { touchingRight, touchingLeft } =
        isTextBoxTouchingContainerSide(item);

      const isPositionedOutsideImage =
        item.position.x > imgWidth / 2 ||
        (imgWidth / 2) * -1 > item.position.x + item.width;

      const isRightSideInsideImage =
        textboxBoundaries.right >= leftImgSide &&
        textboxBoundaries.right <= rightImgSide;

      const isLeftSideInsideImage =
        textboxBoundaries.left >= leftImgSide &&
        textboxBoundaries.left <= rightImgSide;

      const isTouchingImage = isLeftSideInsideImage || isRightSideInsideImage;

      let textboxDisplay = "block";
      let markerDisplay = "none";
      collapsedTextBoxes.current[index] = false;

      if (isPositionedOutsideImage && isTouchingImage) {
        textboxDisplay = "none";
        markerDisplay = "block";
      }

      if (!isPositionedOutsideImage && (touchingLeft || touchingRight)) {
        collapsedTextBoxes.current[index] = true;
        return;
      }

      if (collapsedTextBoxes.current.some((isCollapsed) => isCollapsed)) {
        for (const textbox of textItemsRef.current) {
          textbox.current.style.display = "none";
        }
        for (const marker of markersRef.current) {
          marker.current.style.display = "block";
        }
        return;
      }

      if (collapsedTextBoxes.current.every((isCollapsed) => !isCollapsed)) {
        for (const textbox of textItemsRef.current) {
          textbox.current.style.display = "block";
        }
        for (const marker of markersRef.current) {
          marker.current.style.display = "none";
        }
        return;
      }

      markersRef.current[index].current.style.display = markerDisplay;
      textItemsRef.current[index].current.style.display = textboxDisplay;
    }
  };
  
  const correctTextboxSize = (item: CEImageWithMarkersItem, index: number) => {
    const containerBoundaries: DOMRect =
      contentRef.current?.getBoundingClientRect()!;
    const { touchingRight, touchingLeft, touchingTop, touchingBottom } =
      isTextBoxTouchingContainerSide(item);

    let width = item.width;
    let height = item.height;

    if (touchingRight) {
      const textboxRightSide = item.position.x + item.width;
      const containerRightSide = containerBoundaries.width / 2;
      width = Math.floor(item.width - (textboxRightSide - containerRightSide));
    }

    if (touchingLeft) {
      const textboxLeftSide = item.position.x;
      const containerLeftSide = containerBoundaries.width / -2;
      width = Math.floor(
        item.width - (textboxLeftSide * -1 - containerLeftSide * -1)
      );
    }

    if (touchingTop) {
      const textboxTopSide = item.position.y;
      const containerTopSide = containerBoundaries.height / -2;
      height = Math.floor(
        item.height - (textboxTopSide * -1 - containerTopSide * -1)
      );
    }

    if (touchingBottom) {
      const textboxBottomSide = item.position.y + item.height;
      const containerBottomSide = containerBoundaries.height / 2;
      height = Math.floor(
        item.height - (textboxBottomSide - containerBottomSide)
      );
    }

    if (width !== item.width) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].width`,
          value: width,
        })
      );
    }

    if (height !== item.height) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].height`,
          value: height,
        })
      );
    }
  };

  /**
   * Pushes the texbox if the left or right side of the container is touching it
   * @returns a temporary x position for the text box
   */
  const pushTextbox = (
    containerBoundaries: DOMRect,
    index: number,
    item: CEImageWithMarkersItem
  ) => {
    let tempPositionX = 0;

    const { touchingRight, touchingLeft } =
      isTextBoxTouchingContainerSide(item);

    if (touchingLeft) {
      tempPositionX = (containerBoundaries.width / 2) * -1;

      textItemsRef.current[
        index
      ].current.style.transform = `translate(${tempPositionX}px, ${item.position.y}px)`;
    }

    if (touchingRight) {
      tempPositionX = containerBoundaries.width / 2 - item.width;

      textItemsRef.current[
        index
      ].current.style.transform = `translate(${tempPositionX}px, ${item.position.y}px)`;
    }

    if (touchingRight || touchingLeft) {
      if (!pushedTextboxIds.includes(index)) {
        setPushedTextboxIds((prevState) => [...prevState, index]);
      }
    }

    if (!touchingRight && !touchingLeft) {
      if (pushedTextboxIds.includes(index)) {
        setPushedTextboxIds((prevState) =>
          prevState.filter((id) => id !== index)
        );
      }
    }

    return tempPositionX;
  };

  /**
   * Calculates the text box position
   */
  const calcTextboxPosition = (item: CEImageWithMarkersItem, index: number) => {
    const containerBoundaries: DOMRect =
      contentRef.current?.getBoundingClientRect()!;
    let textboxPos = { ...item.position };
    const imgDomRect = imgRef.current?.getBoundingClientRect();
    if (imgDomRect && containerBoundaries) {
      const cfgImageWidth = Math.round(props.content.cfgImageWidth);
      if (isImgScaledDown() && !editView) {
        const factor = imgDomRect.width / cfgImageWidth;
        textboxPos.x = Math.round(item.position.x * factor);
        textboxPos.y = Math.round(item.position.y * factor);
      }

      if (allowTextboxPush.current) {
        const tempPositionX = pushTextbox(containerBoundaries, index, item);
        if (tempPositionX) {
          textboxPos.x = tempPositionX;
        }
      }
    }

    return textboxPos;
  };

  const keepMarkerInsideImage = (
    imgDomRect: DOMRect,
    markerPos: {
      x: number;
      y: number;
    }
  ) => {
    const fallbackHeight =
      (props.content.cfgImg.height! / props.content.cfgImg.width!) *
      imgDomRect.width;
    const realImgWidth = imgDomRect.width - MARKER_OFFSET;
    const realImgHeight =
      (imgDomRect.height > 0 ? imgDomRect.height : fallbackHeight) -
      MARKER_OFFSET;
    const isInsideImageX =
      markerPos.x > realImgWidth / -2 && markerPos.x < realImgWidth / 2;
    const isInsideImageY =
      markerPos.y < realImgHeight / 2 && markerPos.y > realImgHeight / -2;
    const isOutsideRight = !isInsideImageX && Math.sign(markerPos.x) === 1;
    const isOutsideLeft = !isInsideImageX && Math.sign(markerPos.x) === -1;
    const isOutsideTop = !isInsideImageY && Math.sign(markerPos.y) === -1;
    const isOutsideBottom = !isInsideImageY && Math.sign(markerPos.y) === 1;
    if (isOutsideRight) {
      markerPos.x = realImgWidth / 2;
    }

    if (isOutsideLeft) {
      markerPos.x = realImgWidth / -2;
    }

    if (isOutsideTop) {
      markerPos.y = realImgHeight / -2;
    }

    if (isOutsideBottom) {
      markerPos.y = realImgHeight / 2;
    }
  };

  const repositionMarkerToImgAspectRatio = (
    imgDomRect: DOMRect,
    markerPos: {
      x: number;
      y: number;
    },
    item: CEImageWithMarkersItem
  ) => {
    const cfgImageWidth = Math.round(props.content.cfgImageWidth);
    const cfgImageHeight = Math.round(props.content.cfgImageHeight);
    if (isImgScaledDown()) {
      const fallbackHeight =
        (props.content.cfgImg.height! / props.content.cfgImg.width!) *
        imgDomRect.width;
      const ratioX = imgDomRect.width / cfgImageWidth;
      const ratioY =
        (imgDomRect.height !== 0 ? imgDomRect.height : fallbackHeight) /
        cfgImageHeight;
      markerPos.x = Math.round(item.markerPosition.x * ratioX);
      markerPos.y = Math.round(item.markerPosition.y * ratioY);
    }
  };

  /**
   * Calculates the marker position
   */
  const calcMarkerPosition = (item: CEImageWithMarkersItem) => {
    let markerPos = { ...item.markerPosition };
    const imgDomRect = imgRef.current?.getBoundingClientRect();

    if (imgDomRect) {
      keepMarkerInsideImage(imgDomRect, markerPos);
      repositionMarkerToImgAspectRatio(imgDomRect, markerPos, item);
    }
    return markerPos;
  };

  const makeTextboxVisible = (index: number) => {
    const textbox: HTMLDivElement = textItemsRef.current[index]?.current;
    if (textbox) {
      const richtext = textbox.children[1] as HTMLDivElement;
      const arrow = textbox.children[2] as HTMLDivElement;
      richtext.style.display = "block";
      arrow.style.display = "block";
    }
  };

  const toggleTextboxesVisibility = () => {
    let visible = true;
    textItemsRef.current.forEach((textboxRef: any) => {
      const textbox = textboxRef.current.children[1] as HTMLDivElement;
      const arrow = textboxRef.current.children[2] as HTMLDivElement;

      if (allTextboxesVisible.current) {
        textbox.style.display = "none";
        arrow.style.display = "none";
        visible = false;
      } else {
        textbox.style.display = "block";
        arrow.style.display = "block";
        visible = true;
      }
    });
    allTextboxesVisible.current = visible;
  };

  const onMarkerClick = (item: CEImageWithMarkersItem) => {
    if (!editView) {
      toggleDialog(item);
    }
  };

  const onTextboxResize = (values: ResizeCallbackData, index: number) => {
    if (editView) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].width`,
          value: values.size.width,
        })
      );
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].height`,
          value: values.size.height,
        })
      );
    }
  };

  const onTextboxResizeStop = (index: number, item: CEImageWithMarkersItem) => {
    correctTextboxSize(item, index);
    allowTextboxPush.current = true;
  };

  const elevateMarker = (index: number) => {
    const marker: HTMLDivElement = markersRef.current[index]?.current;
    marker.style.zIndex = "1";
  };

  const lowerMarker = (index: number) => {
    const marker: HTMLDivElement = markersRef.current[index]?.current;
    marker.style.zIndex = "auto";
  };

  const elevateTextbox = (index: number) => {
    const textbox: HTMLDivElement = textItemsRef.current[index]?.current;
    textbox.style.zIndex = "1";
  };

  const lowerTextbox = (index: number) => {
    const textbox: HTMLDivElement = textItemsRef.current[index]?.current;
    textbox.style.zIndex = "auto";
  };

  useEffect(() => {
    // triggers on window width changes
    const img = document.getElementsByClassName(
      `marker-img-${props.position}`
    )[0] as HTMLImageElement;

    if (!img) {
      setShowScaleWarning(false);
    } else {
      setShowScaleWarning(img.offsetWidth < props.content.cfgImageWidth);
    }
  }, [width]);

  return (
    <>
      <ContentElementWrapper
        name={IMAGE_WITH_MARKERS_CE_NAME}
        scopedClassName={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            IMAGE_WITH_MARKERS_CE_NAME,
            IMAGE_WITH_MARKERS_SCOPED_CLASS
          )}
        >
          <Row
            id={
              props.content.cfgAnchorName
                ? `anchor-${props.content.cfgAnchorName}`
                : ""
            }
            className="g-0"
          >
            <Col xs={12}>
              <div className="content" ref={contentRef}>
                <div className="textbox-marker-container">
                  <div className="image">
                    <figure>
                      {props.content.cfgImg ? (
                        <PbContentImage
                          img={props.content.cfgImg}
                          cmsPosition={props.position}
                          cmsFieldImage={"img"}
                          maxWidth={`${Math.round(
                            props.content.cfgImageWidth
                          )}px`}
                          maxHeight={`${props.content.cfgImageHeight}px`}
                          height={"auto"}
                          width="100%"
                          isMobile={props.isMobile}
                          disableEditView
                          className={clsx(
                            "mx-auto",
                            `marker-img-${props.position}`
                          )}
                          imageRef={imgRef}
                          widthLimitSettings={Math.round(
                            props.content.cfgImageWidth
                          )}
                          onLoad={(event) => {
                            // triggers on img width changes in cfg modal
                            setShowScaleWarning(
                              event.currentTarget.offsetWidth <
                                props.content.cfgImageWidth
                            );
                          }}
                        />
                      ) : (
                        <div style={{ height: "300px" }}></div>
                      )}
                    </figure>
                  </div>
                  {props.content.content.map(
                    (item: CEImageWithMarkersItem, index: number) => {
                      const { id: iconId } = getIcon(
                        item.cfgStrMarkerStyle,
                        props.ceSettings?.marker
                      );
                      const showMarkerNumber =
                        editView && iconId ? true : false;
                      const backgroundColor = overlayItemBackgroundColor(
                        item.cfgStrBackgroundColor
                      );
                      const markerColor = overlayItemMarkerColor(
                        item.cfgStrMarkerColor
                      );
                      updateTextboxVisibility(item, index);
                      const markerPos = calcMarkerPosition(item);
                      const textboxPos = calcTextboxPosition(item, index);
                      const showTooltip =
                        editView && pushedTextboxIds.includes(index);

                      return (
                        <React.Fragment key={"draggable-" + index}>
                          <Draggable
                            position={markerPos}
                            onStart={() => elevateMarker(index)}
                            onStop={(e, ui) => onMarkerDragStop(ui, index)}
                            bounds="parent"
                            nodeRef={markersRef.current[index]}
                            disabled={!editView || showScaleWarning}
                          >
                            <div
                              className={clsx(
                                "marker-position-wrapper-free",
                                editView && "edit-mode",
                                cfgShowMarkersOnAllSizes && "visible"
                              )}
                              ref={markersRef.current[index]}
                            >
                              <AnimationWrapper
                                animationIn={item.cfgAnimationIn}
                                animationOut={item.cfgAnimationOut}
                              >
                                <button
                                  className="marker-button"
                                  aria-label={tCms(
                                    "imgwithmarkers-open-text-overlay"
                                  )}
                                  tabIndex={!editView ? 0 : -1}
                                  onClick={() => onMarkerClick(item)}
                                >
                                  <div className="marker-container">
                                    <div
                                      className="marker"
                                      style={{
                                        backgroundColor: markerColor || "blue",
                                      }}
                                    >
                                      {iconId ? (
                                        <div
                                          className={`str-icon-${iconId}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            backgroundSize: "cover",
                                          }}
                                        />
                                      ) : (
                                        <span>{index + 1}</span>
                                      )}
                                      {editView && showMarkerNumber && (
                                        <div className="marker-number">
                                          {index + 1}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </button>
                              </AnimationWrapper>
                            </div>
                          </Draggable>
                          <Draggable
                            position={textboxPos}
                            onStop={(e, ui) => {
                              onTextboxDragStop(ui, index);
                            }}
                            onStart={() => elevateTextbox(index)}
                            bounds="parent"
                            cancel={nonDraggableClasses}
                            nodeRef={textItemsRef.current[index]}
                            disabled={!editView || showScaleWarning}
                          >
                            <Resizable
                              height={item.height}
                              width={item.width}
                              onResize={(
                                e: React.SyntheticEvent,
                                values: ResizeCallbackData
                              ) => {
                                onTextboxResize(values, index);
                              }}
                              onResizeStart={() => {
                                allowTextboxPush.current = false;
                              }}
                              onResizeStop={() => {
                                onTextboxResizeStop(index, item);
                              }}
                            >
                              <div
                                ref={textItemsRef.current[index]}
                                className={clsx(
                                  positionEditMode && editView && "edit-mode",
                                  !editView &&
                                    cfgShowMarkersOnAllSizes &&
                                    "d-none",
                                  "text-box"
                                )}
                                style={{
                                  width: item.width + "px",
                                  height: item.height + "px",
                                }}
                              >
                                <div
                                  className="drag-zone"
                                  onClick={() => makeTextboxVisible(index)}
                                >
                                  <span>{index + 1}</span>
                                </div>
                                {showTooltip && (
                                  <div className="temp-pos">
                                    <CmsTooltip
                                      iconHeight={20}
                                      iconWidth={20}
                                      iconName="circle-question-light"
                                      title={tCms("imgWithMarkers-textboxInfo")}
                                    />
                                  </div>
                                )}
                                <AnimationWrapper
                                  animationIn={item.cfgAnimationIn}
                                  animationOut={item.cfgAnimationOut}
                                >
                                  <div
                                    className={clsx(
                                      "text-content",
                                      editView && "editview"
                                    )}
                                    style={{
                                      backgroundColor:
                                        backgroundColor || "white",
                                    }}
                                    onFocus={() => elevateTextbox(index)}
                                    onBlur={() => lowerTextbox(index)}
                                  >
                                    <CmsGeneralConfigFrame
                                      frameOnHover={true}
                                      hideAddCEButton={true}
                                      moveDirection={"horizontal"}
                                      onDelete={() =>
                                        handleOnMarkerDelete(index)
                                      }
                                      position={index}
                                      arrayLength={props.content.content.length}
                                      openConfigModal={() => {
                                        dispatch(
                                          showNestedConfigModal({
                                            nestedPosition: index,
                                            position: props.position,
                                            contentElementSetting:
                                              ceSettingById(
                                                valueFromStoreSetting(
                                                  props.content
                                                    .cfgStrContentElementSetting
                                                )
                                              ),
                                          })
                                        );
                                      }}
                                    >
                                      <PbContentRichText
                                        className={clsx(richTextClassName)}
                                        content={item.text}
                                        ceSettings={getNestedCeSettingsData(
                                          props.ceSettings?.richtext!
                                        )}
                                        position={props.position}
                                        toolbarBelow
                                        cmsFieldContent={`content[${index}].text`}
                                      />
                                    </CmsGeneralConfigFrame>
                                  </div>
                                </AnimationWrapper>
                              </div>
                            </Resizable>
                          </Draggable>
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {editView && !showScaleWarning ? (
            <Row className="buttons g-0">
              <Col xs="auto">
                <PbButton
                  startIcon={
                    <PbIcon
                      name="plus-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                  onClick={() =>
                    dispatch(
                      cmsAddNestedContentElementThunk(
                        nestedElementAttributes,
                        props.position,
                        "content",
                        props.content.content.length + 1
                      )
                    )
                  }
                >
                  {tCms("add")}
                </PbButton>
              </Col>
              <Col xs="auto">
                <PbButton
                  onClick={() => setPositionEditMode((prevState) => !prevState)}
                >
                  {positionEditMode
                    ? tCms("imgWithMarkers-togglePositionEditMode-active")
                    : tCms("imgWithMarkers-togglePositionEditMode-disabled")}
                </PbButton>
              </Col>
              <Col xs="auto">
                <PbButton onClick={toggleTextboxesVisibility}>
                  {tCms("imgWithMarkers-hideAllTextboxes")}
                </PbButton>
              </Col>
              <Col xs="auto">
                <PbButton
                  onClick={() =>
                    showResetPositionConfirmModal({
                      title: tCms("imgWithMarkers-resetPositionEditMode"),
                      content: tCms(
                        "imgWithMarkers-resetPositionEditModeConfirm"
                      ),
                      acceptBtnText: tCms("resetting"),
                      denyBtnText: tCms("cancel"),
                      xIsDeny: true,
                      icon: "triangle-exclamation-light",
                    })
                  }
                  color="error"
                >
                  {tCms("imgWithMarkers-resetPositionEditMode")}
                </PbButton>
              </Col>
            </Row>
          ) : null}
          {editView && showScaleWarning ? (
            <div className="scale-warning-container">
              <div className="scale-warning">
                {tCms("imgWithMarkers-imgScaleWarning")}
              </div>
            </div>
          ) : null}
        </div>
        <PbGenericModal
          hideHeader
          className={`${IMAGE_WITH_MARKERS_CE_NAME}-text-box ${IMAGE_WITH_MARKERS_SCOPED_CLASS}-modal`}
          open={dialogOpen ? true : false}
          title={tCms("addNewElement")}
          onBackdropClick={() => toggleDialog(undefined)}
          muiDialogProps={{
            fullWidth: true,
            maxWidth: "lg",
            PaperProps: {
              style: {
                minWidth: "150px",
                width: "unset",
                backgroundColor:
                  overlayItemBackgroundColor(
                    dialogContent?.cfgStrBackgroundColor
                  ) ?? "var(--pb-white)",
              },
            },
          }}
          muiDialogContentSxProps={{ paddingRight: "50px !important" }}
        >
          <>
            <button
              className="close-text-box"
              onClick={() => toggleDialog(undefined)}
            >
              <PbIcon
                name="xmark-light"
                width={25}
                height={25}
                className="svg-fill-black"
              />
            </button>
            <PbContentRichText
              className={clsx(richTextClassName)}
              content={dialogContent?.text ?? ""}
              ceSettings={getNestedCeSettingsData(props.ceSettings?.richtext!)}
              position={props.position}
              disableEditView
            />
          </>
        </PbGenericModal>
      </ContentElementWrapper>
      <ImageWithMarkersContentElementStyleGlobal {...props} />
      <ImageWithMarkersContentElementStyleScoped
        scopedSelector={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        {...props}
      />
    </>
  );
});
